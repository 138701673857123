<template>
  <div class="TermsConditions">
    <div class="Wrapper">
      <BaseH2 class="Title Title--h2 Title--dark TermsConditions-Title">
        {{$t('termsConditions.title')}}
      </BaseH2>
      <BaseSubtitle class="TermsConditions-Subtitle">
        {{$t('termsConditions.subtitle')}}
      </BaseSubtitle>
      <BaseSubtitle class="TermsConditions-Subtitle">
        {{$t('termsConditions.caption')}}
      </BaseSubtitle>
      <BaseSupport :support-info="getTermsConditions" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TermsConditions",
  computed: {
    ...mapGetters(['getTermsConditions'])
  }
}
</script>

<style lang="scss">
.TermsConditions {
  padding: 103px 0 40px;

  @media (min-width: $screen-s) {
    padding: 140px 0 0;
  }

  @media (min-width: $screen-m) {
    padding: 140px 0 60px;
  }

  @media (min-width: $screen-l) {
    padding: 235px 0 80px;
  }

  &-Title {
    margin-bottom: 50px;
    text-align: center;
  }

  &-Subtitle {
    text-align: center;
  }
}
</style>
